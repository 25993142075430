import React from "react"

export const initialState = {
  path: '',
  amplitude: null,
  lang: 'es',
  loanValue: 200,
  loanTimeLimit: 3,
  windowWidth: 0,
  categoryFAQs: ["general"],
  modal: {
    display: false,
    content: <div>Loading...</div>
  }
};