import * as LANG from "../utils/lang"

export const SET_PATH = "SET_PATH";
export const setPath = path => ({
  type: SET_PATH, path
});

export const SET_AMPLITUDE = "SET_AMPLITUDE";
export const setAmplitude = amplitude => ({
  type: SET_AMPLITUDE, amplitude
});

export const SET_WINDOW_WIDTH = "SET_WINDOW_WIDTH";
export const setWindowWidth = windowWidth => ({
  type: SET_WINDOW_WIDTH, windowWidth
});

export const SET_LANG = "SET_LANG";
export const setLang = path => ({
  type: SET_LANG, lang: LANG.setLang(path)
});

export const SHOW_MODAL = "SHOW_MODAL";
export const showModal = modal => ({
  type: SHOW_MODAL, modal
});

export const HIDE_MODAL = "HIDE_MODAL";
export const hideModal = () => ({
  type: HIDE_MODAL
});

export const LOAN_VALUE = "LOAN_VALUE";
export const setLoanValue = loanValue => ({
  type: LOAN_VALUE, loanValue
});

export const LOAN_TIME_LIMIT = "LOAN_TIME_LIMIT";
export const setLoanTimeLimit = loanTimeLimit => ({
  type: LOAN_TIME_LIMIT, loanTimeLimit
});

export const SET_CATEGORY_FAQS = "SET_CATEGORY_FAQS";
export const setCategoryFAQs = categoryFAQs => ({
  type: SET_CATEGORY_FAQS, categoryFAQs
});