export const setLang = (pathname) => {
  let lang;
  let urlStrings;

  if (pathname === "/") {
    lang = "es"
  } else {
    urlStrings = pathname.split("/").filter(item => item !== "");
    lang = urlStrings[0] === "en" ? "en" : "es";
  }

  return lang;
};