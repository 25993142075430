import { SET_PATH, SET_AMPLITUDE, SET_WINDOW_WIDTH, SET_LANG, SHOW_MODAL, HIDE_MODAL, LOAN_VALUE, LOAN_TIME_LIMIT, SET_CATEGORY_FAQS } from "./actions";
import { initialState } from "./initialState"

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PATH:
      return { ...state, path: action.path };
    case SET_AMPLITUDE:
      return { ...state, amplitude: action.amplitude };
    case SET_WINDOW_WIDTH:
      return { ...state, windowWidth: action.windowWidth };
    case SET_LANG:
      return { ...state, lang: action.lang };
    case SHOW_MODAL:
      return { ...state, modal: action.modal };
    case HIDE_MODAL:
      return { ...state, modal: initialState.modal };
    case LOAN_VALUE:
      return { ...state, loanValue: action.loanValue };
    case LOAN_TIME_LIMIT:
      return { ...state, loanTimeLimit: action.loanTimeLimit };
    case SET_CATEGORY_FAQS:
      return { ...state, categoryFAQs: action.categoryFAQs };
    default:
      return state;
  }
};

export default appReducer