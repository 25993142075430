// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-conectar-js": () => import("./../../../src/pages/conectar.js" /* webpackChunkName: "component---src-pages-conectar-js" */),
  "component---src-pages-connect-en-js": () => import("./../../../src/pages/connect.en.js" /* webpackChunkName: "component---src-pages-connect-en-js" */),
  "component---src-pages-cuenta-freelancers-js": () => import("./../../../src/pages/cuenta-freelancers.js" /* webpackChunkName: "component---src-pages-cuenta-freelancers-js" */),
  "component---src-pages-educacion-js": () => import("./../../../src/pages/educacion.js" /* webpackChunkName: "component---src-pages-educacion-js" */),
  "component---src-pages-education-en-js": () => import("./../../../src/pages/education.en.js" /* webpackChunkName: "component---src-pages-education-en-js" */),
  "component---src-pages-financial-revolution-en-js": () => import("./../../../src/pages/financial-revolution.en.js" /* webpackChunkName: "component---src-pages-financial-revolution-en-js" */),
  "component---src-pages-financiamiento-js": () => import("./../../../src/pages/financiamiento.js" /* webpackChunkName: "component---src-pages-financiamiento-js" */),
  "component---src-pages-financing-en-js": () => import("./../../../src/pages/financing.en.js" /* webpackChunkName: "component---src-pages-financing-en-js" */),
  "component---src-pages-freelancers-account-en-js": () => import("./../../../src/pages/freelancers-account.en.js" /* webpackChunkName: "component---src-pages-freelancers-account-en-js" */),
  "component---src-pages-funding-donate-js": () => import("./../../../src/pages/funding-donate.js" /* webpackChunkName: "component---src-pages-funding-donate-js" */),
  "component---src-pages-herramientas-js": () => import("./../../../src/pages/herramientas.js" /* webpackChunkName: "component---src-pages-herramientas-js" */),
  "component---src-pages-identidad-js": () => import("./../../../src/pages/identidad.js" /* webpackChunkName: "component---src-pages-identidad-js" */),
  "component---src-pages-identity-en-js": () => import("./../../../src/pages/identity.en.js" /* webpackChunkName: "component---src-pages-identity-en-js" */),
  "component---src-pages-impuestos-sat-js": () => import("./../../../src/pages/impuestos-sat.js" /* webpackChunkName: "component---src-pages-impuestos-sat-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-prev-v-3-en-js": () => import("./../../../src/pages/index-prev-v3.en.js" /* webpackChunkName: "component---src-pages-index-prev-v-3-en-js" */),
  "component---src-pages-index-prev-v-3-js": () => import("./../../../src/pages/index-prev-v3.js" /* webpackChunkName: "component---src-pages-index-prev-v-3-js" */),
  "component---src-pages-index-v-1-en-js": () => import("./../../../src/pages/index-v1.en.js" /* webpackChunkName: "component---src-pages-index-v-1-en-js" */),
  "component---src-pages-index-v-1-js": () => import("./../../../src/pages/index-v1.js" /* webpackChunkName: "component---src-pages-index-v-1-js" */),
  "component---src-pages-index-v-2-en-js": () => import("./../../../src/pages/index-v2.en.js" /* webpackChunkName: "component---src-pages-index-v-2-en-js" */),
  "component---src-pages-index-v-2-js": () => import("./../../../src/pages/index-v2.js" /* webpackChunkName: "component---src-pages-index-v-2-js" */),
  "component---src-pages-marketplace-en-js": () => import("./../../../src/pages/marketplace.en.js" /* webpackChunkName: "component---src-pages-marketplace-en-js" */),
  "component---src-pages-marketplace-js": () => import("./../../../src/pages/marketplace.js" /* webpackChunkName: "component---src-pages-marketplace-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-planes-js": () => import("./../../../src/pages/planes.js" /* webpackChunkName: "component---src-pages-planes-js" */),
  "component---src-pages-plataforma-js": () => import("./../../../src/pages/plataforma.js" /* webpackChunkName: "component---src-pages-plataforma-js" */),
  "component---src-pages-platform-en-js": () => import("./../../../src/pages/platform.en.js" /* webpackChunkName: "component---src-pages-platform-en-js" */),
  "component---src-pages-pricing-en-js": () => import("./../../../src/pages/pricing.en.js" /* webpackChunkName: "component---src-pages-pricing-en-js" */),
  "component---src-pages-revolucion-financiera-js": () => import("./../../../src/pages/revolucion-financiera.js" /* webpackChunkName: "component---src-pages-revolucion-financiera-js" */),
  "component---src-pages-taxes-sat-en-js": () => import("./../../../src/pages/taxes-sat.en.js" /* webpackChunkName: "component---src-pages-taxes-sat-en-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-toolbox-en-js": () => import("./../../../src/pages/toolbox.en.js" /* webpackChunkName: "component---src-pages-toolbox-en-js" */),
  "component---src-pages-wallet-en-js": () => import("./../../../src/pages/wallet.en.js" /* webpackChunkName: "component---src-pages-wallet-en-js" */),
  "component---src-pages-wallet-js": () => import("./../../../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */)
}

